<template>
  <div class="NousRejoindre" >
    <section id="nous-rejoindre-main-section">
      <div class="container">
        <div class="row section-title-margin">
          <div class="col-12 text-center">
            <h3 class="font-alt section-title">
              Nous rejoindre
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col-12 ">
            <p class="section-slogan">
              Une équipe talentueuse et généreuse envers ses clients
            </p>
            <p class="section-explanation">
              Rejoindre Geegz, c'est la garantie d'une équipe <strong>experte</strong> apportant à ses clients
              des compétences éprouvées et une vision précise, <strong>créative</strong>
              et <strong>généreuse</strong>, témoignant d'une disponibilité sans faille et d'une appréhension passionnée des problématiques
              pour apporter des solutions au-delà d'un simple cadre contractuel.
            </p>

            <p class="section-explanation">
              Vous êtes passionné de nouvelles technologies et cherchez sans cesse à étancher votre soif d'apprendre ? <br>
              Vous souhaitez toucher à tous les aspects d'une entreprise ? Du code à l'interaction client ? <br>
              Vous êtes enthousiaste, travailleur et force de proposition ?
            </p>

            <div class="row basic-alt-tabs text-explanation-on-black" style="margin-top: 70px;">
              <div class="col-12 text-center">
                <b-button to='/contact' class="p-3" variant="outline-light" size='lg'>
                  Contactez-nous directement
                </b-button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>


  </div>
</template>

<script>

export default {
  name: 'NousRejoindre',
}
</script>

<style>
#nous-rejoindre-main-section {
  padding-top: 20px;
  padding-bottom: 80px;
  background-color: black;
  color: white;
}


</style>
